import { useEffect, useRef } from 'react';
import { StateSnapshot, VirtuosoHandle } from 'react-virtuoso';
import {
  RestoreFeedBaseProps,
  useRestoreFeedBase,
} from '@/components/feed/restore-feed/use-restore-feed-base.ts';

const feedPositions: Map<string, StateSnapshot> = new Map();

export const hasFeedPosition = (key: string) => feedPositions.has(key);
export const removeFeedPosition = (key: string) => feedPositions.delete(key);

export const useRestoreFeed = ({
  getKey,
  onRestore,
}: RestoreFeedBaseProps = {}) => {
  const { key, style, restoreStateFrom } = useRestoreFeedBase({
    feedPositions,
    onRestore,
    getKey,
  });

  const virtuosoRef = useRef<VirtuosoHandle>(null);

  useEffect(() => {
    const virtuoso = virtuosoRef.current;

    return () => {
      if (virtuoso) {
        virtuoso.getState((state) => feedPositions.set(key, state));
      }
    };
  }, [key]);

  return {
    virtuosoRef,
    restoreStateFrom: restoreStateFrom as StateSnapshot | undefined,
    style,
  };
};
